import React, { Component } from "react";
import "./style.scss";
import Header from "../../header";
import Layout from "../../layout";
import ProjectLayout from "../../projectlayout2";
import sliderImages from "./slider.data";
import SEO from "../../seo";

const details = {
  title: "Delhi Public School, R.K. Puram",
  address: "R.K. Puram New Delhi",
  client: "DPS Society, New Delhi",
  area: "4,50,000 Sq. Ft.",
  project: "Delhi Public School, R.K. Puram, New Delhi (Winning Design Entry)",
  category: "Institutions | Architecture | School",
  status: "Under Municipal Approval",
  backlink: "/projects/institutions/",
};

export default class Projectdpsfaridabad extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="A&D Studio | Institutional Projects | Delhi Public School, R.K.Puram"
          img="https://archdesignsstudio.com/assets/linkpreview.png"
          description="Explore the award-winning campus design and architecture of DPS society’s flagship school."
        />
        <Header isWhite />
        <ProjectLayout details={details} projectimages={sliderImages} />
      </Layout>
    );
  }
}
