const data = [
  {
    src: "https://archdesignsstudio.com/assets/projects-re/dpsrkpuram/1.png",
    text: "1",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/dpsrkpuram/2.png",
    text: "2",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/dpsrkpuram/3.png",
    text: "3",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/dpsrkpuram/4.png",
    text: "4",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/dpsrkpuram/5.png",
    text: "5",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/dpsrkpuram/6.png",
    text: "6",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/dpsrkpuram/7.png",
    text: "7",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/dpsrkpuram/8.png",
    text: "8",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/dpsrkpuram/9.png",
    text: "9",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/dpsrkpuram/10.png",
    text: "10",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/dpsrkpuram/11.png",
    text: "11",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/dpsrkpuram/12.png",
    text: "12",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/dpsrkpuram/13.png",
    text: "13",
  },
];

export default data;
